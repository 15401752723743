<script>
import ShakepayGuide from "./guides/shakepay-guide.vue";
import BitBuyGuide from "./guides/bitbuy-guide.vue";

const guides = [
  { name: "Shakepay", guide: ShakepayGuide },
  { name: "BITBUY", guide: BitBuyGuide },
];

export default {
  props: {
    paymentMethod: String,
  },
  computed: {
    guide() {
      return guides.find(({ name }) => name === this.paymentMethod)?.guide;
    },
  },
  render(h) {
    if (!this.guide) {
      return null;
    }
    return h(this.guide);
  },
};
</script>

<style scoped></style>
