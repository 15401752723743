<template>
  <div>
    <header class="header">
      <button class="back-button" @click="goBack">
        <img src="./assets/back-arrow.svg" alt="arrow" class="mr-2" />
        {{ $t("faqTab.back") }}
      </button>
      <h1 class="payment-title">{{ $t("payment.title") }}</h1>
    </header>
    <main class="main">
      <div class="step-controls">
        <button v-if="step === 1" class="outlined-button" @click="goBack">
          {{ $t("payment.cancel") }}
        </button>
        <button v-else class="outlined-button" @click="prevStep">
          {{ $t("payment.prevStep") }}
        </button>
        <button
          class="button next"
          :disabled="selectedPaymentMethodOption === null"
          v-if="step < 3"
          @click="nextStep"
        >
          {{ $t("payment.nextStep") }}
        </button>
      </div>
      <div class="main-content">
        <div class="first-col" />
        <div>
          <stepper :steps="steps" :stepNumber="step" />
          <payment-first-section
            v-if="step === 1"
            :paymentMethodsOptions="paymentMethodsOptions"
            :selectedPaymentMethodOption="selectedPaymentMethodOption"
            @methodSelect="selectPaymentMethod"
          />
          <payment-second-section
            v-if="step === 2"
            :cryptoAmount="cryptoAddress ? cryptoAddress.cryptoAmount : 0"
            :availableCoins="availableCoins"
            :value="selectedCurrency"
            @input="selectCurrency"
            @applyPromocode="applyPromocode"
          />
          <payment-third-section
            v-if="step === 3"
            :segwitAddress="segwitAddress"
            :legacyAddress="legacyAddress"
            :paymentMethod="selectedPaymentMethodName"
          />
        </div>
        <div class="right">
          <div class="payment-info">
            <div class="payment-info-row">
              <p>{{ $t("payment.timer") }}</p>
              <payment-timer />
            </div>
            <div class="payment-info-row">
              <p>{{ $t("payment.totalPrice") }}</p>
              <p class="price">${{ selectedPaymentMethodPrice.toFixed(2) }}</p>
            </div>
          </div>
          <div v-if="isCheckingPaymentStatus" class="payment-status">
            <div class="payment-waiting">
              <div class="payment-loading" />
              {{ $t("payment.waiting") }}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import VueService from "../../services/VueService";

const CRYPTO_PAYMENT_ID = 85;

const steps = ["payment.step1", "payment.step2", "payment.step3"];

export default {
  components: {
    PaymentTimer: () => import("./components/payment-timer.vue"),
    PaymentFirstSection: () => import("./components/payment-first-section.vue"),
    PaymentSecondSection: () =>
      import("./components/payment-second-section.vue"),
    PaymentThirdSection: () => import("./components/payment-third-section.vue"),
    Stepper: () => import("./components/stepper.vue"),
  },

  data() {
    return {
      paymentData: null,
      availablePaymentMethods: [],
      selectedPaymentMethodId: null,
      selectedPaymentMethodOption: null,
      selectedPaymentMethod: null,
      selectedCurrency: null,
      cryptoAddress: null,
      step: 1,
      timeout: null,
    };
  },

  methods: {
    async setupData() {
      if (!this.transactionId) {
        return;
      }
      const loader = this.$loading.show();
      try {
        const availablePaymentMethodsPromise = ApiService.paymentApi.getAvailablePaymentMethods(
          this.transactionId
        );
        const paymentDataPromise = ApiService.paymentApi.getSelectedPaymentMethod(
          {
            transactionId: this.transactionId,
            paymentMethodId: CRYPTO_PAYMENT_ID,
          }
        );
        const [{ availablePaymentMethods }, paymentData] = await Promise.all([
          availablePaymentMethodsPromise,
          paymentDataPromise,
        ]);
        this.availablePaymentMethods = availablePaymentMethods;
        this.paymentData = paymentData;
        loader.hide();
      } catch {
        loader.hide();
      }
    },

    async selectPaymentMethod(selectedPaymentMethodId) {
      const selectedOption = this.paymentMethodsOptions.find(
        ({ value }) => value === selectedPaymentMethodId
      );

      const isCrypto = !!selectedOption?.isCrypto;

      if (isCrypto) {
        this.selectedPaymentMethodId = CRYPTO_PAYMENT_ID;
        this.selectedPaymentMethodOption = selectedPaymentMethodId;
      } else {
        this.selectedPaymentMethodId = +selectedPaymentMethodId;
        this.selectedPaymentMethodOption = +selectedPaymentMethodId;
      }

      await this.getPaymentMethod(this.selectedPaymentMethodId);
      this.selectedCurrency =
        this.availableCoins.length > 1
          ? this.availableCoins[1].value
          : this.availableCoins[0].value;

      this.getCryptoAddress();
    },

    async getPaymentMethod() {
      const loader = this.$loading.show();

      try {
        const res = await ApiService.paymentApi.getSelectedPaymentMethod({
          transactionId: this.transactionId,
          paymentMethodId: this.selectedPaymentMethodId,
        });
        this.selectedPaymentMethod = res;
        loader.hide();
      } catch {
        loader.hide();
      }
    },

    async selectCurrency(value) {
      this.selectedCurrency = value;
      this.getCryptoAddress();
    },

    async getCryptoAddress() {
      const loader = this.$loading.show();
      try {
        const res = await ApiService.paymentApi.getCryptoAddress({
          currency: this.selectedCurrency,
          transactionId: this.transactionId,
        });
        this.cryptoAddress = res;
        loader.hide();
      } catch (err) {
        console.error(err);
        loader.hide();
      }
    },

    async applyPromocode(promocode) {
      try {
        const { error } = await ApiService.paymentApi.applyPromocode({
          transactionId: this.transactionId,
          promocode,
        });

        if (error) {
          if (error === "promoNotValid") {
            VueService.showToastMessage({
              message: this.$t("payment.promoNotValid"),
              type: "error",
              position: "bottom-right",
            });
          }
          return;
        } else {
          VueService.showToastMessage({
            message: this.$t("payment.promocodeSuccess"),
            type: "success",
            position: "bottom-right",
          });
        }
        await this.getPaymentMethod();
      } catch (err) {
        console.error(err);
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    nextStep() {
      this.step += 1;
    },

    prevStep() {
      this.step -= 1;
    },

    checkPaymentStatus() {
      const timeout = 5000;

      const paymentStatuses = {
        APPROVED: "Approved",
        REJECTED: "Rejected",
      };

      this.timeout = setTimeout(async () => {
        const res = await ApiService.paymentApi.getTransactionStatus(
          this.transactionId,
          this.cryptoAddress.addresses.map(({ address }) => address)
        );
        const { status, note } = res;

        switch (status) {
          case paymentStatuses.APPROVED:
            VueService.showToastMessage({
              message: `${this.$t("payment.paymentProcessed")}`,
              type: "success",
            });
            this.$router.push("/Subscriptions");
            break;

          case paymentStatuses.REJECTED:
            VueService.showToastMessage({
              message: `${this.$t("payment.paymentRejected")} ${note}`,
              type: "error",
            });
            break;

          default:
            this.checkPaymentStatus();
        }
      }, timeout);
    },

    cancelPaymentStatusCheck() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
  },

  computed: {
    paymentMethodsOptions() {
      return this.availablePaymentMethods.reduce((acc, { id, name }) => {
        if (id === CRYPTO_PAYMENT_ID) {
          this.paymentData.availableCoins.forEach(({ id, name }) => {
            acc.push({
              label: name,
              value: id,
              isCrypto: true,
            });
          });
        } else {
          acc.push({
            label: name,
            value: id,
            isCrypto: false,
          });
        }
        return acc;
      }, []);
    },

    transactionId() {
      return this.$route.query.id;
    },

    selectedProductName() {
      return (
        this.selectedPaymentMethod?.packageName ??
        this.paymentData?.packageName ??
        "–"
      );
    },

    selectedPaymentMethodName() {
      return this.availablePaymentMethods.find(
        ({ id }) => id === this.selectedPaymentMethodId
      )?.name;
    },

    selectedPaymentMethodPrice() {
      return (
        this.selectedPaymentMethod?.amount ?? this.paymentData?.amount ?? 0
      );
    },

    availableCoins() {
      if (this.selectedPaymentMethodId === CRYPTO_PAYMENT_ID) {
        const res =
          this.selectedPaymentMethod?.availableCoins?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [];
        return res;
      }
      const method = this.availablePaymentMethods.find(
        ({ id }) => id === this.selectedPaymentMethodId
      );
      return (
        method?.description
          ?.split(",")
          .map((coin) => ({ label: coin, value: coin })) ?? [
          { label: "BTC", value: "BTC" },
        ]
      );
    },

    amounts() {
      return {
        btc:
          this.selectedPaymentMethodId === CRYPTO_PAYMENT_ID
            ? this.cryptoAddress?.cryptoAmount ?? "0"
            : this.selectedPaymentMethod?.btcAmount ?? "0",
        usdt: this.selectedPaymentMethodPrice,
      };
    },

    segwitAddress() {
      return this.cryptoAddress?.addresses[0].address;
    },

    legacyAddress() {
      return this.cryptoAddress?.addresses[1]?.address;
    },

    showGuide() {
      return this.selectedPaymentMethodId !== CRYPTO_PAYMENT_ID;
    },

    steps() {
      return steps;
    },

    isCheckingPaymentStatus() {
      return this.timeout !== null;
    },
  },

  async created() {
    await this.setupData();
  },

  watch: {
    step() {
      if (this.step !== 3) {
        this.cancelPaymentStatusCheck();
      } else {
        this.checkPaymentStatus();
      }
    },
  },

  beforeUnmount() {
    this.cancelPaymentStatusCheck();
  },
};
</script>

<style scoped src="./style.css" />
