<template>
  <div class="guide-container">
    <h3 class="guide-title">BITBUY {{ $t("global.guide") }}</h3>
    <guide-step
      :title="$t('faqTab.troubleshooting.bitBuy.steps.step1Title')"
      :content="$t('faqTab.troubleshooting.bitBuy.steps.step1Content')"
      :note="$t('faqTab.troubleshooting.bitBuy.steps.step1Note')"
    />
    <guide-step
      :title="$t('faqTab.troubleshooting.bitBuy.steps.step2Title')"
      :content="$t('faqTab.troubleshooting.bitBuy.steps.step2Content')"
      :note="$t('faqTab.troubleshooting.bitBuy.steps.step2Note')"
    />
    <guide-step
      :title="$t('faqTab.troubleshooting.bitBuy.steps.step3Title')"
      :content="$t('faqTab.troubleshooting.bitBuy.steps.step3Content')"
    />
    <guide-step
      :title="$t('faqTab.troubleshooting.bitBuy.steps.step4Title')"
      :content="$t('faqTab.troubleshooting.bitBuy.steps.step4Content')"
    />
    <guide-step
      :title="$t('faqTab.troubleshooting.bitBuy.steps.step5Title')"
      :content="$t('faqTab.troubleshooting.bitBuy.steps.step5Content')"
      :note="$t('faqTab.troubleshooting.bitBuy.steps.step5Note')"
      :isLast="true"
    />
  </div>
</template>

<script>
export default {
  components: {
    GuideStep: () => import("./guide-step.vue"),
  },
};
</script>

<style scoped src="./style.css" />
