<template>
  <div class="step-container">
    <div class="step-title">
      {{ title }}
    </div>
    <div class="step-content">
      <p v-if="description" class="step-description">{{ description }}</p>
      <ol class="step-list">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="step-item"
          v-html="step"
        ></li>
      </ol>
      <p v-if="note" class="step-note">{{ note }}</p>

      <div v-if="isLast" class="step-regards">
        <p class="main-text">{{ $t("faqTab.troubleshooting.regards") }}</p>
        <p class="main-text">{{ $t("faqTab.troubleshooting.support") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    description: String,
    note: String,
    isLast: Boolean,
  },
  computed: {
    steps() {
      return this.content?.split(";") || [];
    },
  },
};
</script>

<style scoped src="./style.css" />
