<template>
    <div class="main-change-password">
        <div class="change-password-general-container">
            <div class="change-password">
                <Header />
                <Wrapper>
                    <div class="change-password-general">
                        <h1 class="change-password-header">{{ $t('generalTabs.changePassword') }}</h1>
                        <NoteComponent>
                            <p>{{ $t('generalTabs.changePasswordNote') }}</p>
                        </NoteComponent>
                        <div class="change-password-item">
                            <label class="change-password-input-text">{{ $t("generalTabs.newPassword") }}</label>
                            <input v-model="processedPassword" class="change-password-input"
                                :type="isPasswordShow ? 'text' : 'password'" required @keyup="passwordRules">
                            <img v-if="!isPasswordShow" @click="toggleShowPassword('password')" class="eye-img"
                                src="@/components/assets/eye.svg" alt="">
                            <img v-else class="eye-img" @click="toggleShowPassword('password')"
                                src="@/components/assets/close-eye.svg" alt="">
                            <span class="change-password-error" v-if="processedPassword.length > 0"> {{ `${passwordMin} ${passwordMax} ${passwordRequired} ${passwordSpace} ${passwordSpecial}` }}</span>
                        </div>
                        <div class="change-password-item">
                            <label class="change-password-input-text">{{ $t("generalTabs.repeatPassword") }}</label>
                            <input v-model="processedPasswordVerification" class="change-password-input"
                                :type="isPasswordVerificationShow ? 'text' : 'password'" required
                                @keyup="passwordVerificationRules">
                            <img v-if="!isPasswordVerificationShow" @click="toggleShowPassword('passwordVerification')"
                                class="eye-img" src="@/components/assets/eye.svg" alt="">
                            <img v-else class="eye-img" @click="toggleShowPassword('passwordVerification')"
                                src="@/components/assets/close-eye.svg" alt="">
                            <span class="change-password-error" v-if="processedPasswordVerification.length > 0">{{
                                passwordVerificationMatch
                                }}</span>
                        </div>
                        <button class="change-password-btn" :class="activeButton ? '' : 'change-pass-inactive-btn'"
                            @click="submit(password)">Change Password</button>
                    </div>
                </Wrapper>
            </div>
        </div>
        <Envelope />
        <div v-if="msg" class="responce-message" :class="msg.type">
            {{ msg.text }}
        </div>
    </div>
</template>

<script>

export default {
    props: {
        submit: Function,
        rules: Object,
        msg: Object,
        password: String,
        passwordVerification: String,
        updatePasswordVerification: Function,
        updatePassword: Function
    },
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Header: () => import('@/components/SideNavPc.vue'),
        Envelope: () => import('@/components/Envelope.vue'),
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    computed: {
        activeButton() {
            const hasPasswords = this.processedPassword.length > 0 && this.processedPasswordVerification.length > 0;
            const noErrors = this.passwordMin === '' && this.passwordMax === '' && this.passwordRequired === '' && this.passwordSpecial === '' && this.passwordSpace === '' && this.passwordVerificationMatch === '';

            return hasPasswords && noErrors;
        },
        processedPassword: {
            get() {
                return this.password;
            },
            set(value) {
                this.updatePassword(value);
            }
        },
        processedPasswordVerification: {
            get() {
                return this.passwordVerification;
            },
            set(value) {
                this.updatePasswordVerification(value);
            }
        }
    },
    data() {
        return {
            isPasswordShow: false,
            isPasswordVerificationShow: false,
            passwordMin: '',
            passwordMax: '',
            passwordRequired: '',
            passwordSpecial: '',
            passwordSpace: '',
            passwordVerificationMatch: ''
        };
    },
    methods: {
        toggleShowPassword(pass) {
            if (pass === 'password') this.isPasswordShow = !this.isPasswordShow
            if (pass === 'passwordVerification') this.isPasswordVerificationShow = !this.isPasswordVerificationShow
        },
        passwordRules() {
            this.passwordMin = this.rules.min(this.processedPassword)
            this.passwordMax = this.rules.max(this.processedPassword)
            this.passwordRequired = this.rules.required(this.processedPassword)
            this.passwordSpecial = this.rules.special(this.processedPassword)
            this.passwordSpace = this.rules.space(this.processedPassword)
            this.rules.resetVerification()

            if (this.passwordMin === true) this.passwordMin = ''
            if (this.passwordMax === true) this.passwordMax = ''
            if (this.passwordRequired === true) this.passwordRequired = ''
            if (this.passwordSpecial === true) this.passwordSpecial = ''
            if (this.passwordSpace === true) this.passwordSpace = ''
        },
        passwordVerificationRules() {
            this.passwordVerificationMatch = this.rules.matchingPassword(this.processedPasswordVerification)
            if (this.passwordVerificationMatch === true) this.passwordVerificationMatch = ''
        }
    }
};
</script>
<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.main-change-password {
    display: flex;
    justify-content: space-between;
}

.change-password-header {
    margin-bottom: 16px;
}

.change-password {
    width: 100%;
    width: fit-content;
}

.change-password-general {
    width: 70%;
}

.change-password-header {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.change-password-input-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 16, 38px;
    margin-bottom: 8px;
}

.change-password-item {
    position: relative;
}

.change-password-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    outline: none;
    background-color: #FFFFFF0A;
    backdrop-filter: blur(10px);


    font-weight: 400;
    font-size: 13px;
    line-height: 16, 38px;
    margin-bottom: 16px;
}

.eye-img {
    max-width: 19px;
    position: absolute;
    top: 47%;
    right: 3%;
    cursor: pointer;
}

.change-password-btn {
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17, 64px;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 16px;
    transition: 0.2s all;
}

.change-pass-inactive-btn {
    background-color: #3C3A46;
    color: #1D1B29;
    pointer-events: none;
}

.change-password-error {
    position: absolute;
    bottom: -5px;
    right: 0%;
    color: #FF254C;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
}

.responce-message {
    position: absolute;
    bottom: 10%;
    right: 10%;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    padding: 12px 16px;
    border-radius: 8px;
}

.responce-message.error-msg {
    color: #FF254C;
    background-color: #FF254C14;
    border: 1px solid #FF254C;
}

.responce-message.success-msg {
    color: #1EE049;
    background-color: #1EE04914;
    border: 1px solid #1EE049;
}

@media only screen and (max-width: 1500px) {
    .change-password-general-container {
        width: 70%;
    }

    .change-password {
        width: 100%;
    }
}

@media only screen and (max-width: 1090px) {
    .main-change-password {
        flex-direction: column;
        align-items: center;
    }

    .change-password-general-container {
        width: 100%;
    }

    .responce-message {
        bottom: 40%;
        right: 32px;
    }
}

@media only screen and (max-width: 768px) {
    .change-password-general {
        width: 100%;
    }

    .change-password-btn {
        width: 100%;
    }

    .responce-message {
        bottom: 45%;
    }
}
</style>
